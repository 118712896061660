<template>
  <CommonEmployeeDetailsModal
    :title="
      $t('EMPLOYEESGENERAL.MESSAGE.MANAGE', {
        employee: this.selectedEmployee.name,
      })
    "
  >
    <div class="d-flex justify-content-center">
      <b-avatar
        :src="avatarUrl"
        variant="light"
        size="15em"
        class="mx-auto mb-5"
      ></b-avatar>
    </div>

    <CommonSchoolImageUpload
      @uploadClicked="handleUpload"
      @cancel-clicked="changePhase"
      @webcam-clicked="changePhase"
      :phase="phase"
    ></CommonSchoolImageUpload>

    <CommonEmployeeDetailForm
      :form-set="selectedEmployee"
      :is-busy="isBusy"
      @submit="handleSubmit"
      @check-code="checkCode"
      :code-exist-status="codeStatus"
    ></CommonEmployeeDetailForm>
  </CommonEmployeeDetailsModal>
</template>

<script>
import CommonEmployeeDetailsModal from "@/modules/school/components/shared/employee/modal/CommonEmployeeDetailsModal";
import CommonEmployeeDetailForm from "@/modules/school/components/shared/employee/form/CommonEmployeeDetailForm";
import CommonSchoolImageUpload from "@/modules/school/components/shared/utility/CommonSchoolImageUpload";

export default {
  name: "StaffPageDetailsModal",
  components: {
    CommonSchoolImageUpload,
    CommonEmployeeDetailForm,
    CommonEmployeeDetailsModal,
  },
  props: {
    title: {
      String,
    },
    selectedEmployee: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isBusy: false,
      phase: "default",
      codeStatus: false,
    };
  },

  mounted() {
    console.log("Mounted", this.selectedEmployee);
  },

  methods: {
    /**
     * Handle Staff general details form submission
     *
     * @param form
     */
    handleSubmit(form) {
      console.log(form);

      const payload = {
        ...form,
        id: this.selectedEmployee.id,
      };

      this.isBusy = true;
      this.$store
        .dispatch("updateStaffGeneralDetails", payload)
        .then((res) => {
          console.log(res);
          this.$bvModal.msgBoxOk("Success!!");
        })
        .finally(() => {
          this.isBusy = false;
          this.$emit("detailsUpdated");
        });
    },

    /**
     * Handle image upload button event
     *
     * @param image
     */
    handleUpload(image) {
      console.log("Staff image: ", image);
      this.$store
        .dispatch("uploadStaffImage", {
          image: image,
          id: this.selectedEmployee.id,
        })
        .then((res) => {
          console.log(res.data);
          this.$bvModal.msgBoxOk(
            "Successfully uploaded image. Image can now be used for facial device recognition.",
            {
              title: "Image uploaded",
              centered: true,
            }
          );
          this.selectedEmployee.image_url = res.data.url;
        })
        .finally(() => {
          this.imageBusy = !this.imageBusy;
          this.phase = "default";
          this.$emit("detailsUpdated");
        });
    },

    checkCode(code) {
      console.log("Watching: ", code);

      if (code) {
        const payload = {
          code: code.code,
          id: code.id,
        };

        console.log("Kat Sini");
        this.$store
          .dispatch("checkExistingStaffCode", payload)
          .then((response) => {
            console.log(response);
            this.codeStatus = response.data.status === "true";
            if (response.data.status === "true") {
              console.log("Data Exist");
            }
            console.log("New Code Status: ", this.codeStatus);
          });
      }
    },
    changePhase() {
      this.phase = this.phase === "WEBCAM_PHASE" ? "default" : "WEBCAM_PHASE";
    },
  },

  computed: {
    avatarUrl() {
      return this.selectedEmployee.image_url;
    },
  },
};
</script>

<style scoped></style>
