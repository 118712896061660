<template>
  <div>
    <StaffTable :reload="reload"></StaffTable>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import StaffTable from "@/modules/school/components/staff/list/StaffPageTable";

export default {
  name: "StaffListPage",
  components: {
    StaffTable,
  },
  data() {
    return {
      reload: false,
    };
  },
  mounted() {
    console.log("Staff List");
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("SIDEBAR.COMPANY") },
      { title: this.$t("SIDEBAR.EMPLOYEE"), route: "/school/staff/list" },
    ]);
  },
  methods: {
    closeSideBar() {
      const sideBar = this.$refs["employee-list-sidebar-ref"];
      sideBar.hide();
      this.reload = !this.reload;
    },
    refresh() {
      this.reload = !this.reload;
    },
  },
};
</script>

<style scoped></style>
