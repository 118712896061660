<template>
  <b-form class="form" v-on:submit.prevent="save">
    <!--  begin:First Name   -->
    <b-form-group
      :label="$t('EMPLOYEESGENERAL.MESSAGE.FIRSTNAME')"
      label-cols-lg="2"
    >
      <b-form-input
        id="employee-first-name-input"
        class="form-control h-auto"
        v-model="$v.form.firstName.$model"
        :state="validateState('firstName')"
        :placeholder="$t('USERFORM.NAME')"
      >
      </b-form-input>
    </b-form-group>
    <!--  end:First Name   -->

    <!--  begin:Last Name    -->
    <b-form-group
      :label="$t('EMPLOYEESGENERAL.MESSAGE.LASTNAME')"
      label-cols-lg="2"
    >
      <b-form-input
        id="employee-email-input"
        class="form-control h-auto"
        v-model="$v.form.lastName.$model"
        :state="validateState('lastName')"
        :placeholder="$t('USERFORM.LASTNAME')"
      >
      </b-form-input>
    </b-form-group>
    <!--  end:Last Name    -->

    <!--  begin:Email    -->
    <b-form-group
      :label="$t('EMPLOYEESGENERAL.MESSAGE.EMAIL')"
      label-cols-lg="2"
    >
      <b-form-input
        id="employee-email-input"
        class="form-control h-auto"
        v-model="$v.form.email.$model"
        :state="validateState('email')"
        :placeholder="$t('USERFORM.EMAIL')"
      >
      </b-form-input>
    </b-form-group>
    <!--  end:Email    -->

    <!-- begin:Unique Code -->
    <b-form-group
      :label="$t('EMPLOYEESGENERAL.MESSAGE.UNIQUE')"
      label-cols-lg="2"
    >
      <b-form-input
        id="employee-unique-code-input"
        class="form-control h-auto"
        v-model="$v.form.uniqueCode.$model"
        :state="validateState('uniqueCode')"
        :placeholder="$t('USERFORM.UNIQUECODE')"
        debounce="500"
      >
      </b-form-input>
      <b-form-invalid-feedback v-if="!$v.form.uniqueCode.$params.codeStatus">
        {{ $t("EMPLOYEESGENERAL.MESSAGE.UNIQUE_VALIDATION") }}
      </b-form-invalid-feedback>
    </b-form-group>
    <!--  end:Unique    -->

    <!--begin::Action-->
    <div class="d-flex justify-content-end pt-3">
      <b-button
        variant="primary"
        type="submit"
        ref="submit"
        v-bind:class="{ 'spinner spinner-right spinner-light': isBusy }"
      >
        {{ $t("USERS.DETAILS.SUBMIT") }}
      </b-button>
    </div>
    <!--end::Action-->
  </b-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  email,
  maxLength,
  minLength,
  required,
} from "vuelidate/lib/validators";

export default {
  name: "CommonEmployeeDetailForm",
  mixins: [validationMixin],
  props: {
    formSet: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
      default: true,
    },
    codeExistStatus: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      form: {
        firstName: this.formSet.firstName,
        lastName: this.formSet.lastName,
        email: this.formSet.email,
        uniqueCode: this.formSet.code,
        uniqueCodeConfirm: this.codeExistStatus,
      },
      codeStatus: this.codeExistStatus,
    };
  },
  validations: {
    form: {
      firstName: {
        required,
        minLength: minLength(2),
      },
      lastName: {
        required,
        minLength: minLength(2),
      },
      email: {
        required,
        email,
      },
      uniqueCode: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(20),
        codeExists: (value, vm) => {
          return !vm.uniqueCodeConfirm;
        },
      },
      uniqueCodeConfirm: {
        required,
      },
    },
  },

  mounted() {
    console.log("Form data set", this.formSet);
  },

  computed: {
    checkExistCode() {
      console.log("Code exist is", this.codeExistStatus);
      return this.codeExistStatus;
    },
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    save() {
      console.log("Emit Common Details to submit event");
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        console.log("Form in complete");
        return;
      }

      this.$emit("submit", {
        first_name: this.form.firstName,
        last_name: this.form.lastName,
        email: this.form.email,
        code: this.form.uniqueCode,
      });
    },
  },

  watch: {
    "form.uniqueCode": {
      handler(code) {
        this.$emit("check-code", {
          code: code,
          id: this.formSet.id,
        });
        console.log("Finish Emitting", code);
      },
      deep: true,
    },
    codeExistStatus: {
      handler(exist) {
        console.log("Code Exist changing..", exist);
        this.form.uniqueCodeConfirm = exist;
      },
    },
  },
};
</script>

<style scoped></style>
